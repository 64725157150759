@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -125;
	}
}

svg.spinner {
	animation: rotate 2s linear infinite;
}

svg.spinner circle {
	stroke-linecap: round;
	stroke-width: 5;
	stroke-opacity: 30%;
}

svg.spinner .circle {
	stroke-linecap: round;
	stroke-width: 5;
	stroke-opacity: 100%;
	animation: dash 2.5s ease-in-out infinite;
}
